import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { roundCurrency } from '../../functions/round-currency.function';

@Component({
  selector: 'app-dashboard-stats',
  templateUrl: './dashboard-stats.component.html',
  styleUrls: ['./dashboard-stats.component.scss']
})
export class DashboardStatsComponent implements OnChanges {
  @Input()
  public active: number;

  @Input()
  public invited: number;

  @Input()
  public premium: number;

  public values = new Map<string, string>();

  public ngOnChanges(changes: SimpleChanges) {
    for (const key in changes) {
      this.values.set(key, roundCurrency(changes[key].currentValue));
    }
  }
}
