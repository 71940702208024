import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-date',
  templateUrl: './date.component.html',
  styleUrls: ['./date.component.scss']
})
export class DateComponent {
  @Input()
  public value: any;

  @Input()
  public format = "E dd/MM/y H:mm";
}
