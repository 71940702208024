import { Platform } from "@angular/cdk/platform";
import { Component, ContentChild, ElementRef, HostListener, Input, Optional, TemplateRef } from "@angular/core";
import { ControlValueAccessor, NgControl, ValidationErrors } from "@angular/forms";

let id=1;
function getId() { return id++ };

@Component({
  selector: "app-date-input",
  templateUrl: "./date-input.component.html",
  styleUrls: ["./date-input.component.scss"],
})
export class DateInputComponent implements ControlValueAccessor {
  public id = getId();

  constructor(
    @Optional() private ngControl: NgControl,
    private el: ElementRef,
    private platform: Platform,
  ) {
    if (ngControl) {
      ngControl.valueAccessor = this;
    }
  }

  @Input()
  public placeholder: string;

  @Input()
  public label: string;

  @Input()
  public isInvalid = false;

  @Input()
  public maxlength: number;

  @Input()
  public uppercase: boolean;

  @Input()
  public disabled: boolean;

  @Input()
  public readonly: boolean;

  public value: string;
  public showPassword = false;
  public wasPassword = false;

  private onChane: (value: string) => void;
  private onTouchedFn: () => void;

  @HostListener("click")
  public setFocus(): void {
    this.el.nativeElement.querySelector("input").focus();
  }

  public getHasValue(): boolean {
    return !!this.value;
  }

  public getIsInvalid(): boolean {
    return (this.ngControl?.invalid && (this.ngControl?.dirty || this.ngControl.touched) || this.isInvalid);
  }

  public get errors(): ValidationErrors {
    return this.ngControl.errors;
  }

  public onInput(event: any): void {
    const value = event?.target?.value;
    this.value = value;

    this.onChane && this.onChane(value);
  }

  public onBlur(): void {
    this.onTouchedFn && this.onTouchedFn();
  }

  public writeValue(obj: string): void {
    this.value = obj;
  }
  public registerOnChange(fn: (value: string) => void): void {
    this.onChane = fn;
  }
  public registerOnTouched(fn: () => void): void {
    this.onTouchedFn = fn;
  }
  public setDisabledState?(isDisabled: boolean): void {
    // throw new Error("Method not implemented.");
  }

  public onIconClicked(event) {
    event.preventDefault();
    event.stopPropagation();

    const input = this.el.nativeElement.querySelector("input");

    !this.platform.SAFARI
      ? input.showPicker()
      : input.focus();
  }
}
