import { AfterViewInit, Component, ContentChild, ElementRef, EventEmitter, Input, OnInit, Output, TemplateRef } from '@angular/core';
import { FormControl } from '@angular/forms';
import { BehaviorSubject } from 'rxjs';
import { PagingParams } from '../../services/api.models';
import { DataSource } from './data-source';

@Component({
  selector: 'app-table',
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.scss']
})
export class TableComponent implements OnInit, AfterViewInit {
  @Input()
  public dataSource: DataSource;

  @Input()
  public defaultSortBy: string;

  @Input()
  public defaultSortDirection: "ASC" | "DESC" = "ASC";

  @Input()
  public list = false;

  @Input()
  public toolbox = false;

  @Output()
  public rowClick = new EventEmitter<any>;

  @ContentChild("header")
  public header: TemplateRef<any>;

  @ContentChild("body")
  public body: TemplateRef<any>;

  @ContentChild("trigger")
  public trigger: ElementRef<any>;

  public searchControl = new FormControl();

  public rows: any = [];
  // public meta: MetaData = {};
  // public meta$ = new BehaviorSubject(this.meta);

  public last = false;
  public isLoading = true;

  private intersectionObserver: IntersectionObserver;
  private pagingParams: PagingParams = { page: 0, itemsPerPage: 10, sortBy: "NAME", sortDirection: "ASC" };

  public pagingParams$ = new BehaviorSubject(this.pagingParams);

  constructor(
    private el: ElementRef,
  ) {
    this.searchControl.valueChanges.subscribe(term => {
      this.onSearchChanged(term);
    });
  }

  public refresh() {
    this.modifyPagingParams({ page: 0 });
  }

  private modifyPagingParams(params: PagingParams) {
    this.isLoading = true;
    this.pagingParams = {...this.pagingParams, ...params};
    this.dataSource.request(this.pagingParams);
    this.pagingParams$.next(this.pagingParams);
  }

  // sort by column clicked
  public onSortByClicked(key: string): void {
    let { sortBy, sortDirection } = this.pagingParams;

    if (sortBy !== key) {
      sortBy = key;
      sortDirection = "ASC";
    } else {
      sortDirection = sortDirection === "ASC"
        ? "DESC"
        : "ASC";
    }

    this.modifyPagingParams({ sortBy, sortDirection, page: 0});
  }

  public onSearchChanged(term: string): void {
    this.modifyPagingParams({ searchPhrase: term, page: 0 });
  }

  public ngOnInit() {
    this.dataSource.connect().subscribe(result => {
      // console.log("rows: ", result);
      // const rows = result.clients || result.logs || result;
      const rows = result?.rows;

      if (!rows) return;

      this.rows = this.pagingParams.page === 0
        ? rows
        : [...this.rows, ...rows];

      this.last = result.last;
      this.isLoading = false;
    });

    if (this.defaultSortBy) {
      const params: PagingParams = { sortBy: this.defaultSortBy };

      if (this.defaultSortDirection) {
        params.sortDirection = this.defaultSortDirection;
      }

      this.modifyPagingParams(params);
    }
  }

  public ngAfterViewInit() {
    this.setupObserver();
  }

  private setupObserver() {
    if (!IntersectionObserver) {
      return;
    }

    const callback: IntersectionObserverCallback = (entries) => entries.forEach(e => e.isIntersecting && this.loadMore());
    const trigger = this.el.nativeElement.querySelector(".trigger");

    if (!trigger) return;

    this.intersectionObserver = new IntersectionObserver(callback);
    this.intersectionObserver.observe(trigger);
  }

  private loadMore() {
    // console.log("load more");
    !this.isLoading && this.modifyPagingParams({ page: this.pagingParams.page + 1 });
  }
}
