<textarea 
       [attr.placeholder]="placeholder"
       [attr.rows]="3"
       [class.isInvalid]="getIsInvalid()"
       [class.uppercase]="uppercase"
       [value]="value || null"
       [attr.maxlength]="maxlength"
       [readOnly]="readonly"
       [attr.disabled]="disabled ? true : null"
       (input)="onInput($event)"
       (blur)="onBlur()">
</textarea>


<div *ngIf="label" class="label" [class.hasValue]="getHasValue()">
  {{ label }}
</div>

