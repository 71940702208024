import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import {LoginRequestDto, LoginUserAccountResponseDto, ResetPasswordDto, SignupRequestDto, SignupResponseDto, UserAccountDto, VerifyCodeRequestDto, VerifyCodeResponseDto} from "../../auth/auth.models";
import { HttpService } from "../services/http.service";

const API_NAME = "/web/auth";

@Injectable({ providedIn: "root" })
export class AuthApi {

  constructor(
    private httpService: HttpService,
  ) { }

  public signup(token: string, dto: SignupRequestDto): Observable<SignupResponseDto> {
    return this.httpService.put(`${API_NAME}/${token}/signup`, dto);
  }

  public verifyCode(token: string, dto: VerifyCodeRequestDto): Observable<VerifyCodeResponseDto> {
    return this.httpService.post(`${API_NAME}/${token}/verify-code`, dto);
  }

  public resetPassword(token: string, dto: ResetPasswordDto): Observable<any> {
    return this.httpService.post(`${API_NAME}/${token}/reset-password`, dto);
  }

  public resendCode(token: string): Observable<void> {
    return this.httpService.post(`${API_NAME}/${token}/resend-code`);
  }

  public loginUserAccount(dto: LoginRequestDto): Observable<LoginUserAccountResponseDto> {
    return this.httpService.post(`${API_NAME}/login`, dto);
  }

  public getCurrentUser(): Observable<UserAccountDto> {
    return this.httpService.get(`${API_NAME}/current`);
  }

  public logout(): Observable<any> {
    return this.httpService.get(`${API_NAME}/logout`);
  }

  public requestResetPassword(email: string): Observable<any> {
    return this.httpService.post(`${API_NAME}/request-reset-password`, { email });
  }
}
