import {LoginUserAccountResponseDto, UserAccountDto, UserDto} from "../auth.models";

export namespace AuthStateActions {
  export class Initialize {
    static readonly type = "[Auth] Initialize";
    constructor(public token: string, public userAccountDto: UserAccountDto) { }
  }

  export class YourDetailsSaved {
    static readonly type = "[Auth] Your Details Saved";
    constructor(public token: string, public user: Partial<UserDto>) { }
  }

  export class SignupVerificationSuccess {
    static readonly type = "[Auth] Signup Verification Success"
    constructor(public dto: UserAccountDto) { }
  }

  export class CurrentUser {
    static readonly type = "[Auth] Current User"
    constructor(public dto: UserAccountDto) { }
  }

  export class Logout {
    static readonly type = "[Auth] Logout"
  }

  export class Login {
    static readonly type = "[Auth] Login"
    constructor(public dto: LoginUserAccountResponseDto) { }
  }

  export class LoginVerificationSuccess {
    static readonly type = "[Auth] Login Verification Success"
    constructor(public dto: UserAccountDto) { }
  }

  export class InitializePasswordReset {
    static readonly type = "[Auth] Initialize Password Reset"
    constructor(public token: string) { }
  }
}
