import { Component, HostBinding, Input } from '@angular/core';
import { DashboardClientStatus } from '../../../shared/services/dashboard.api';

@Component({
  selector: 'app-client-status',
  templateUrl: './client-status.component.html',
  styleUrls: ['./client-status.component.scss']
})
export class ClientStatusComponent {
  @Input()
  @HostBinding("class")
  public status: DashboardClientStatus;

  public getStatus(): DashboardClientStatus { return this.status === "TWO_FACTOR_AUTH_SETUP" ? "INVITED" : this.status }

  public formatStatus() { return this.getStatus()?.replace(/_/g, " ") }
}
