import { Directive, ElementRef, Input } from '@angular/core';
import { LogoDto } from '../services/api.models';

export abstract class AuthTokenProvider {
  abstract getToken(): string;
}

@Directive({
  selector: '[appSrcset]'
})
export class SrcsetDirective {

  @Input() set appSrcset(value: LogoDto) {
    this.dto = value;
    this.compose();
  }

  private dto: LogoDto

  constructor(
    private el: ElementRef,
  ) { }

  private compose() {
    if (this.dto) {
      const srcset = [this.dto.thumbnail1xUrl, this.dto.thumbnail2xUrl, this.dto.thumbnail3xUrl]
        .map((t, i) => `${t} ${i+1}x`)
        .join(", ");

      this.el.nativeElement.src = this.dto.thumbnail1xUrl;
      this.el.nativeElement.srcset = srcset;
    } else {
      this.el.nativeElement.src = null;
      this.el.nativeElement.srcset = null;
    }
  }
}
