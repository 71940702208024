import { HttpClient, HttpEvent, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { share } from "rxjs/operators";
import { environment } from "../../../environments/environment";

@Injectable({ providedIn: "root" })
export class HttpService {

  public apiUrl = (api: string) => `${environment.apiUrl}${api}`;

  constructor(private http: HttpClient) { }

  public get<R>(api: string, params?: HttpParams): Observable<R> {
    return this.http.get<R>(this.apiUrl(api), { params }).pipe(
      share(),
    );
  }

  public post<B, R>(api: string, body?: B, params?: HttpParams): Observable<R> {
    return this.http.post<R>(this.apiUrl(api), body, { params }).pipe(
      share(),
    );
  }

  public postEvents<B, R>(api: string, body?: B, params?: HttpParams): Observable<HttpEvent<R>> {
    // const opts = { observe: 'events', reportProgress: true, params };

    return this.http.post<R>(this.apiUrl(api), body, { observe: 'events', reportProgress: true, params }).pipe(
      share(),
    );
  }

  public put<B, R>(api: string, body: B, params?: HttpParams): Observable<R> {
    return this.http.put<R>(this.apiUrl(api), body, { params }).pipe(
      share(),
    );
  }

  public delete<R>(api: string, params?: HttpParams): Observable<R> {
    return this.http.delete<R>(this.apiUrl(api), { params }).pipe(
      share(),
    );
  }
}
