import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { fadeInAnimation } from '../../animations/fadeIn.animation';

@Component({
  selector: 'app-dialog',
  templateUrl: './dialog.component.html',
  styleUrls: ['./dialog.component.scss'],
  animations: [fadeInAnimation],
})
export class DialogComponent implements OnInit, OnDestroy
{
  @Input()
  public isLoading = false;

  public ngOnInit() {
    this.setNoScroll();
  }

  public ngOnDestroy() {
    this.setNoScroll(false);
  }

  private setNoScroll(noScroll = true) {
    const body = document.querySelector("body");

    noScroll 
      ? body.classList.add("no-scroll")
      : body.classList.remove("no-scroll");
  }
}
