import { HttpEvent } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { HttpService } from "../services/http.service";
import { AttachmentDto, UploadDto } from "./gcs.models";

const API_NAME = "/web/gcs";

@Injectable({ providedIn: "root" })
export class GcsApi {

  constructor(
    private httpService: HttpService,
  ) { }

  public upload(dto: UploadDto): Observable<HttpEvent<AttachmentDto>> {
    const form = new FormData();
    form.append("file", dto.file);

    return this.httpService.postEvents(`${API_NAME}`, form);
  }
}
