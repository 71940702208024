import { Injectable } from "@angular/core";
import { Subject } from "rxjs";


export type ToastSeverity = "info" | "error";
export type ToastMessage = string;


@Injectable({providedIn: "root"})
export class ToastService {
  private _message$ = new Subject<[ToastMessage, ToastSeverity]>();

  public message$ = this._message$.asObservable();

  public add(message: ToastMessage, severity: ToastSeverity = "info", error?: any) {
    this._message$.next([error ? this.formatMessageWithError(message, error) : message, severity]);
  }

  private formatMessageWithError(message: string, error: any) {
    return `${message}: ${error.error.customMessage?.en || error.message || error.toString()}`;
  }
}
