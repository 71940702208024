<div class="container" (click)="onClicked()">
  <div *ngIf="value" class="selected">
    <ng-container *ngTemplateOutlet="valueTemplate || defaultValueTemplate, context: { $implicit: value }">
    </ng-container>
  </div>

  <div class="label" [class.hasValue]="!!value">
    {{ label }}
  </div>

  <svg class="icon" [class.isOpen]="isOpen" width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_1884_42717)">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M4.99999 6.93945L11 12.9395L17 6.93945L18.0607 8.00011L11 15.0608L3.93933 8.00011L4.99999 6.93945Z" fill="black"/>
    </g>
    <defs>
    <clipPath id="clip0_1884_42717">
    <rect width="22" height="22" rx="4" fill="white"/>
    </clipPath>
    </defs>
  </svg>
</div>

<ng-template #drawer>
  <div class="drawer">
    <div class="list">
      <div *ngFor="let item of items" class="item" [ngClass]="item.class" (click)="onItemClicked(item)">
        <ng-container *ngTemplateOutlet="itemTemplate || defaultItemTemplate, context: { $implicit: item, isSelected: item === value }">
        </ng-container>
      </div>
    </div>

    <ng-container *ngTemplateOutlet="drawerBottomTemplate">
    </ng-container>
  </div>
</ng-template>

<ng-template #defaultItemTemplate let-item>{{ item }}</ng-template>
<ng-template #defaultValueTemplate let-item>{{ item }}</ng-template>
