import { Directive, HostBinding, HostListener, Input } from '@angular/core';
import { TableComponent } from '../components/table/table.component';
import { PagingParams } from '../services/api.models';

@Directive({
  selector: '[appSortBy]'
})
export class SortByDirective {
  @Input()
  @HostBinding("class.sortBy")
  public appSortBy: string;

  @HostListener("click")
  public onClicked() {
    this.table.onSortByClicked(this.appSortBy);
  }

  @HostBinding("class.arrow-asc") get asc() { return this.pagingParams?.sortBy === this.appSortBy && this.pagingParams.sortDirection === "ASC" }
  @HostBinding("class.arrow-desc") get desc() { return this.pagingParams?.sortBy === this.appSortBy && this.pagingParams.sortDirection === "DESC" }
  private pagingParams: PagingParams;

  constructor(
    private table: TableComponent,
  ) {
    this.table.pagingParams$.subscribe(pagingParams => this.pagingParams = pagingParams);
  }
}
