import { Selector, State } from "@ngxs/store";
import { AuthStateModel } from "./auth.state";

@State<AuthStateModel>({
  name: "auth",
})
export class AuthStateSelectors {
  @Selector()
  static user(model: AuthStateModel) {
    return model.userAccountDto?.user;
  }

  @Selector()
  static account(model: AuthStateModel) {
    return model.loginAccount;
  }
}
