import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngxs/store';
import { AuthStateActions } from 'projects/admin/src/modules/auth/state/auth.actions';
import { EmailTokensApi } from 'projects/admin/src/modules/shared/services/email-tokens.api';
import { ToastService } from 'projects/admin/src/modules/shared/services/toast.service';

@Component({
  selector: 'app-token-crossroads',
  templateUrl: './token-crossroads.component.html',
  styleUrls: ['./token-crossroads.component.scss']
})
export class TokenCrossroadsComponent {
  public isLoading = true;

  public get token() { return this.route.snapshot.paramMap.get("token") }

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private store: Store,
    private emailTokensApi: EmailTokensApi,
    private toastService: ToastService,
  ) {
    this.initialize();
  }

  private initialize() {
    if (this.token && this.token.length === 36) {
    } else {
      this.isLoading = false;
      this.toastService.add("Invalid token, please use the link in an invitation email", "error");
      return;
    }

    this.emailTokensApi.get(this.token).subscribe(
      result => {
        if (result.token?.type === "USER_INVITATION" && result?.token?.userAccount) {
          this.store.dispatch(new AuthStateActions.Initialize(this.token, result.token.userAccount));
          setTimeout(() => this.router.navigate(["/auth/welcome"]));
        }

        if (result.token?.type === "RESET_PASSWORD") {
          this.store.dispatch(new AuthStateActions.InitializePasswordReset(this.token));
          setTimeout(() => this.router.navigate(["/auth/recovery-new-password"]));
        }
      },
      e => {
        console.log("error", e);
        this.toastService.add("Error", "error", e);
        this.isLoading = false;
      }
    );
  }
}
