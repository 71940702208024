import { OverlayModule } from '@angular/cdk/overlay';
import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {MatIcon, MatIconModule} from "@angular/material/icon";
import {ButtonComponent} from './components/button/button.component';
import {CodeInputComponent} from './components/code-input/code-input.component';
import { DropdownComponent } from './components/dropdown/dropdown.component';
import { SpinnerComponent } from './components/spinner/spinner.component';
import {TextInputComponent} from './components/text-input/text-input.component';
import {ToastComponent} from './components/toast/toast.component';
import {FormFieldDirective} from './directives/form-field.directive';
import { DialogComponent } from './components/dialog/dialog.component';
import { DateInputComponent } from './components/date-input/date-input.component';
import { TextareaInputComponent } from './components/textarea-input/textarea-input.component';
import { DocumentUploaderComponent } from './components/document-uploader/document-uploader.component';
import { ProgressBarComponent } from './components/progress-bar/progress-bar.component';
import { DragoverDirective } from './directives/dragover.directive';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DebugFormComponent } from './components/debug-form/debug-form.component';
import { DropdownInputComponent } from './components/dropdown-input/dropdown-input.component';
import { NgxMaskModule } from 'ngx-mask';
import { DashboardStatsComponent } from './components/dashboard-stats/dashboard-stats.component';
import { TableComponent } from './components/table/table.component';
import { SortByDirective } from './directives/sort-by.directive';
import { DateComponent } from './components/date/date.component';
import { CurrencyComponent } from './components/currency/currency.component';
import { RouterModule } from '@angular/router';
import { ConfirmationDialogComponent } from './components/confirmation-dialog/confirmation-dialog.component';
import { ValueTransformerDirective } from './directives/value-transformer.directive';
import { CurrencyInputComponent } from './components/currency-input/currency-input.component';
import { MenuComponent } from './components/menu/menu.component';
import { IconButtonComponent } from './components/icon-button/icon-button.component';
import { SrcsetDirective } from './directives/srcset.directive';
import { ClientStatusComponent } from '../dashboard/components/client-status/client-status.component';

const exported = [
  ButtonComponent,
  TextInputComponent,
  TextareaInputComponent,
  DateInputComponent,
  ToastComponent,
  CodeInputComponent,
  SpinnerComponent,
  DropdownComponent,
  DropdownInputComponent,
  DialogComponent,
  DocumentUploaderComponent,
  ProgressBarComponent,
  DebugFormComponent,
  DashboardStatsComponent,
  TableComponent,
  DateComponent,
  CurrencyComponent,
  ConfirmationDialogComponent,
  CurrencyInputComponent,
  MenuComponent,
  IconButtonComponent,
  ClientStatusComponent,

  SortByDirective,
  FormFieldDirective,
  DragoverDirective,
  ValueTransformerDirective,
  SrcsetDirective,
]

const reexported = [
  MatIcon,
]

@NgModule({
  exports: [
    ...exported, 
    ...reexported,
  ],
  declarations: [
    ...exported,
  ],
  imports: [
    CommonModule,
    MatIconModule,
    OverlayModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    NgxMaskModule.forRoot(),

  ],
})
export class SharedModule { }
