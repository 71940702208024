import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from '../modules/auth/guards/auth.guard';
import { LayoutComponent } from './components/layout/layout.component';
import { TokenCrossroadsComponent } from './pages/token-crossroads/token-crossroads.component';
import { TokenMobileComponent } from './pages/token-mobile/token-mobile.component';

const routes: Routes = [
  {
    path: "token-web/:token",
    component: TokenCrossroadsComponent,
    data: {
      title: "Token",
    }
  },
  {
    path: "token-mobile/:token",
    component: TokenMobileComponent,
    data: {
      title: "Download App",
    }
  },

  {
    path: "auth",
    loadChildren: () => import("../modules/auth/auth.module").then(m => m.AuthModule),
  },

  {
    path: "",
    component: LayoutComponent,
    children: [
      {
        path: "dashboard",
        loadChildren: () => import("../modules/dashboard/dashboard.module").then(m => m.DashboardModule),
        canActivate: [AuthGuard],
      },
      {
        path: "users",
        loadChildren: () => import("../modules/user/user.module").then(m => m.UserModule),
        canActivate: [AuthGuard],
      },
      {
        path: "**",
        redirectTo: "/dashboard",
      },
    ]
  },

  {
    path: "debug",
    loadChildren: () => import("../modules/debug/debug.module").then(m => m.DebugModule),
  },

  // {
  //   path: "**",
  //   redirectTo: "/auth/login",
  // }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
