<div class="overlay" [@fadeIn]></div>

<div class="vb-dialog" [class.isLoading]="isLoading" [@fadeIn]>
  <ng-content select=".header"></ng-content>

  <ng-content select=".content"></ng-content>

  <ng-content select=".debug"></ng-content>

  <ng-content select=".footer"></ng-content>

  <div *ngIf="isLoading">
    <app-spinner></app-spinner>
  </div>
</div>
