<div class="container" (click)="onClicked()">
  <div class="selected">
    <ng-container *ngTemplateOutlet="valueTemplate || defaultValueTemplate, context: { $implicit: value }">
    </ng-container>
  </div>

  <svg class="icon" [class.isOpen]="isOpen" width="15" height="16" viewBox="0 0 15 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M11.7857 5.5H3.21429L7.50001 10.5L11.7857 5.5Z" fill="#8391A1"/>
  </svg>

</div>

<ng-template #drawer>
  <div class="drawer">
    <div class="list">
      <div *ngFor="let item of items" class="item" [ngClass]="item.class" (click)="onItemClicked(item)">
        <ng-container *ngTemplateOutlet="itemTemplate || defaultItemTemplate, context: { $implicit: item, isSelected: item === value }">
        </ng-container>
      </div>
    </div>

    <ng-container *ngTemplateOutlet="drawerBottomTemplate">
    </ng-container>
  </div>
</ng-template>

<ng-template #defaultItemTemplate let-item>{{ item }}</ng-template>
<ng-template #defaultValueTemplate let-item>{{ item }}</ng-template>
