import { HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { HttpService } from "../services/http.service";
import { PaginatedRezult, PagingParams } from "./api.models";
import { DashboardStatsResponseDto, LogsResults } from "./dashboard.models";

export type DashboardClientStatus =
  | "INVITED"
  | "TWO_FACTOR_AUTH_SETUP"
  | "ACTIVATED"
  | "DELETED"

export interface DashboardClientProfileDto {
  id: string;
  creationTime: string;
  modificationTime: string;
}

export interface DashboardClientDto {
  id: string;
  firstName: string;
  lastName: string;
  email: string;
  lastAction: string;
  status: DashboardClientStatus;
  clientProfiles: DashboardClientProfileDto[];
}

export interface DashboardClientsResponseDto extends PaginatedRezult<DashboardClientDto, "clients"> {
}

const API_NAME = "/web/dashboard";

@Injectable({ providedIn: "root" })
export class DashboardApi {

  constructor(
    private httpService: HttpService,
  ) { }

  public getClients(pagingParams: PagingParams): Observable<DashboardClientsResponseDto> {
    const params = new HttpParams().appendAll(pagingParams as any);

    return this.httpService.get(`${API_NAME}/clients`, params);
  }

  public getLogs(pagingParams: PagingParams): Observable<LogsResults> {
    const params = new HttpParams().appendAll(pagingParams as any);

    return this.httpService.get(`${API_NAME}/logs`, params);
  }

  public getStats(): Observable<DashboardStatsResponseDto> {
    return this.httpService.get(`${API_NAME}/stats`);
  }
}
