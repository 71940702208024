import { HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { HttpService } from "../services/http.service";
import { AddPolicyRequestDto, FindAllPoliciesResponseDto, PolicyDto } from "./policies.models";
import * as R from "ramda";

const API_NAME = "/web/policies";

export function prepareParams(params: Record<string, any>) {
  return R.reject(R.isNil, params);
}

@Injectable({ providedIn: "root" })
export class PoliciesApi {

  constructor(
    private httpService: HttpService,
  ) { }

  public getPolicy(id: string): Observable<PolicyDto> {
    return this.httpService.get(`${API_NAME}/${id}`);
  }

  public addPolicy(dto: AddPolicyRequestDto): Observable<PolicyDto> {
    return this.httpService.post(`${API_NAME}`, dto);
  }

  public editPolicy(id: string, dto: AddPolicyRequestDto): Observable<PolicyDto> {
    return this.httpService.put(`${API_NAME}/${id}`, dto);
  }

  public deletePolicy(id: string): Observable<any> {
    return this.httpService.delete(`${API_NAME}/${id}`);
  }

  public findAll(clientProfileId: string, userAccountId?: string): Observable<FindAllPoliciesResponseDto> {
    const params = prepareParams({ clientProfileId, userAccountId });

    return this.httpService.get(`${API_NAME}`, new HttpParams().appendAll(params));
  }
}
