<div *ngIf="toolbox" class="toolbar">
  <app-text-input label="Search Clients" size="h48" icon="search" [formControl]="searchControl"></app-text-input>
  <app-button variant="primary" size="h48" leftIcon="add" routerLink="/dashboard/add-client">Add Client</app-button>
</div>

<div class="table">
  <table class="vi-table">
    <tr *ngIf="!list">
      <ng-container *ngTemplateOutlet="header">
      </ng-container>
    </tr>

    <tbody>
      <tr *ngFor="let row of rows" (click)="rowClick.emit(row)" [class.clickable]="rowClick.observed">
        <ng-container *ngTemplateOutlet="body, context: { $implicit: row }">
        </ng-container>
      </tr>

      <tr *ngIf="!last">
        <td colspan="999">
          <span class="trigger">
            <app-spinner></app-spinner>
          </span>
        </td>
      </tr>
    </tbody>
  </table>
</div>
