import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import {EmailTokenResponseDto} from "../../auth/auth.models";
import { HttpService } from "../services/http.service";

const API_NAME = "/web/email-tokens";

@Injectable({ providedIn: "root" })
export class EmailTokensApi {

  constructor(
    private httpService: HttpService,
  ) { }

  public get(token: string): Observable<EmailTokenResponseDto> {
    return this.httpService.get(`${API_NAME}/${token}`);
  }
}
