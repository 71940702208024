<div routerLink="/dashboard" class="logo"></div>

<div class="separator"></div>

<div (click)="isItemsMobileOpen = true" class="hamburger">
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M21.6 6.86157H2.40002V7.60003H21.6V6.86157Z" fill="#001F5C"/>
    <path d="M21.6 12.0308H2.40002V12.7692H21.6V12.0308Z" fill="#001F5C"/>
    <path d="M21.6 17.2001H2.40002V17.9385H21.6V17.2001Z" fill="#001F5C"/>
  </svg>

  Menu
</div>

<div class="items-desktop">
  <span *ngFor="let item of items" [routerLink]="item.path">{{ item.heading }}</span>

  <span class="last">
    <app-dropdown [items]="dropdownItems" (itemClick)="onItemClicked($event)">
      <ng-template let-item #itemTemplate>{{ item.heading }}</ng-template>
      <ng-template let-item #valueTemplate>{{ userAccount?.user.firstName }} {{ userAccount?.user.lastName }}</ng-template>
    </app-dropdown>
  </span>
</div>

<div *ngIf="isItemsMobileOpen" class="items-mobile" [@menu]>
  <svg class="close" (click)="isItemsMobileOpen = false" width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M27.1339 3.74997L3.75003 27.1339L2.86615 26.25L26.25 2.86609L27.1339 3.74997Z" fill="#001F5C"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M3.75003 2.86609L27.1339 26.25L26.25 27.1339L2.86615 3.74997L3.75003 2.86609Z" fill="#001F5C"/>
  </svg>

  <span *ngFor="let item of mobileItems" [routerLink]="item.path" (click)="isItemsMobileOpen = false">{{ item.heading }}</span>
</div>


