import { Directive, HostBinding, HostListener } from '@angular/core';

function preventDefaultAndStopPropagation(event: Event) {
  event.preventDefault();
  event.stopPropagation();
}

@Directive({
  selector: '[appDragover]'
})
export class DragoverDirective {
  @HostBinding('class.dragover')
  public dragover = false;

  @HostListener('dragover', ['$event']) 
  public onDragOver(event: Event) {
    preventDefaultAndStopPropagation(event);
    this.dragover = true;
  }

  @HostListener('dragleave', ['$event']) 
  public onDragLeave(event: Event) {
    preventDefaultAndStopPropagation(event);
    this.dragover = false;
  }

  @HostListener('drop', ['$event']) 
  public onDrop(event: Event) {
    preventDefaultAndStopPropagation(event);
    this.dragover = false;
  }
}
