import { AfterViewInit, Component, ElementRef, Input, ViewChild } from '@angular/core';
import { fadeInAnimation } from '../../animations/fadeIn.animation';

export type ButtonVariant = "primary" | "white" | "outline" | "red";

@Component({
  selector: 'app-button',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss'],
  animations: [fadeInAnimation],
})
export class ButtonComponent implements AfterViewInit {
  @Input()
  public disabled = false;

  @Input()
  public variant: ButtonVariant = "outline";

  @Input()
  public size: string = "";

  @Input()
  public icon: string;

  @Input()
  public leftIcon: string;

  @Input()
  public rightIcon: string;

  @Input()
  public isLoading = false;

  @ViewChild("more")
  public contentRef: ElementRef;

  public hasContent = false;

  public ngAfterViewInit() {
    if (this.contentRef?.nativeElement.childNodes.length > 0) {
      setTimeout(() => this.hasContent = true);
    }
  }
}
