<div class="container">
  <input type="tel" maxlength="1" pattern="\d*" autocorrect="off" autocomplete="off" autocapitalize="none" spellcheck="false" placeholder="" [disabled]="disabled">
  <input type="tel" maxlength="1" pattern="\d*" autocorrect="off" autocomplete="off" autocapitalize="none" spellcheck="false" placeholder="" [disabled]="disabled">
  <input type="tel" maxlength="1" pattern="\d*" autocorrect="off" autocomplete="off" autocapitalize="none" spellcheck="false" placeholder="" [disabled]="disabled">

  <span>–</span>
  
  <input type="tel" maxlength="1" pattern="\d*" autocorrect="off" autocomplete="off" autocapitalize="none" spellcheck="false" placeholder="" [disabled]="disabled">
  <input type="tel" maxlength="1" pattern="\d*" autocorrect="off" autocomplete="off" autocapitalize="none" spellcheck="false" placeholder="" [disabled]="disabled">
  <input type="tel" maxlength="1" pattern="\d*" autocorrect="off" autocomplete="off" autocapitalize="none" spellcheck="false" placeholder="" [disabled]="disabled">
</div>

<pre *ngIf="log">
{{ log }}
</pre>
