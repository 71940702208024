<div *ngIf="prefix" class="prefix" [class.hasValue]="getHasValue()">{{prefix}}</div>

  <input *ngIf="mask" [type]="type"
          [min]="min"
          [max]="max"
          class="input"
          [class.currency]="_currency"
          [ngClass]="size"
          [(ngModel)]="value"
          (ngModelChange)="onModelChange()"
          [mask]="mask"
          [thousandSeparator]="_currency ? ',' : null"
          [attr.placeholder]="placeholder"
          [attr.autocomplete]="autocomplete"
          [attr.pattern]="(_currency || _integer) ? '\\d*' : (pattern || undefined)"
          [attr.readonly]="readonly || undefined"
          (blur)="onBlur()">

  <input *ngIf="!mask" [type]="type"
        [min]="min"
        [max]="max"
        class="input"
        [ngClass]="size"
        [(ngModel)]="value"
        (ngModelChange)="onModelChange()"
        [attr.placeholder]="placeholder"
        [attr.autocomplete]="autocomplete"
        [attr.pattern]="type === 'number' ? '\\d*' : (pattern || undefined)"
        [attr.readonly]="readonly || undefined"
        (blur)="onBlur()">

<!-- <input *ngIf="!mask" -->
<!--        [type]="type" -->
<!--        [attr.placeholder]="placeholder" -->
<!--        [class.isInvalid]="getIsInvalid()" -->
<!--        [class.uppercase]="uppercase" -->
<!--        [ngClass]="size" -->
<!--        [value]="value || null" -->
<!--        [attr.maxlength]="maxlength" -->
<!--        [class.has-suffix-template]="!!suffixTemplate" -->
<!--        [attr.was-password]="wasPassword" -->
<!--        [readOnly]="readonly" -->
<!--        [attr.disabled]="disabled ? true : null" -->
<!--        [attr.pattern]="(type === 'tel') ? '\\d*' : undefined" -->
<!--        (input)="onInput($event)" -->
<!--        (blur)="onBlur()"> -->

<!-- <input *ngIf="mask" -->
<!--        [type]="type" -->
<!--        [mask]="mask" -->
<!--                     [thousandSeparator]="_currency ? ',' : null" -->
<!--        [attr.placeholder]="placeholder" -->
<!--        [class.isInvalid]="getIsInvalid()" -->
<!--        [class.uppercase]="uppercase" -->
<!--        [ngClass]="size" -->
<!--        [value]="value || null" -->
<!--        [attr.maxlength]="maxlength" -->
<!--        [class.has-suffix-template]="!!suffixTemplate" -->
<!--        [attr.was-password]="wasPassword" -->
<!--        [readOnly]="readonly" -->
<!--        [attr.disabled]="disabled ? true : null" -->
<!--        [attr.pattern]="(type === 'tel') ? '\\d*' : undefined" -->
<!--        (input)="onInput($event)" -->
<!--        (blur)="onBlur()"> -->

<i *ngIf="icon" class="material-symbols-outlined">{{ icon }}</i>

<div *ngIf="type === 'password' || showPassword" class="postfix">
  <div class="password-eye" (click)="onEyeClicked()">
    <ng-container *ngTemplateOutlet="showPassword ? eyeOffIcon : eyeIcon"></ng-container>
  </div>
</div>

<div *ngTemplateOutlet="suffixTemplate"></div>

<div *ngIf="label" class="label" [ngClass]="size" [class.hasValue]="getHasValue()">
  {{ label }}
</div>

<ng-template #eyeIcon>
  <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_1482_10872)">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M1.53142 13.1411C1.5315 13.141 1.53159 13.1408 0.916672 12.8333C0.301753 12.5259 0.301873 12.5256 0.302006 12.5254L0.302343 12.5247L0.303273 12.5228L0.306148 12.5172L0.315958 12.498C0.324298 12.4817 0.336238 12.4587 0.351751 12.4294C0.382773 12.3708 0.42811 12.2868 0.487548 12.1812C0.606373 11.9699 0.781862 11.6714 1.01232 11.3145C1.47239 10.6022 2.15601 9.65062 3.05053 8.69646C4.82704 6.80151 7.51701 4.8125 11 4.8125C14.483 4.8125 17.173 6.80151 18.9495 8.69646C19.844 9.65062 20.5276 10.6022 20.9877 11.3145C21.2181 11.6714 21.3936 11.9699 21.5125 12.1812C21.5719 12.2868 21.6172 12.3708 21.6483 12.4294C21.6638 12.4587 21.6757 12.4817 21.6841 12.498L21.6939 12.5172L21.6967 12.5228L21.6977 12.5247L21.698 12.5254C21.6981 12.5256 21.6983 12.5259 21.0833 12.8333C20.4684 13.1408 20.4685 13.141 20.4686 13.1411L20.4675 13.1389L20.4612 13.1266C20.4553 13.1151 20.4458 13.0969 20.4329 13.0725C20.4071 13.0237 20.3674 12.9501 20.314 12.8553C20.2073 12.6655 20.0462 12.3911 19.8326 12.0605C19.4047 11.3978 18.7706 10.516 17.9464 9.63688C16.2854 7.86516 13.9337 6.1875 11 6.1875C8.06633 6.1875 5.71463 7.86516 4.05365 9.63688C3.22942 10.516 2.59533 11.3978 2.16738 12.0605C1.95383 12.3911 1.79273 12.6655 1.68596 12.8553C1.63261 12.9501 1.59291 13.0237 1.56708 13.0725C1.55417 13.0969 1.54474 13.1151 1.53881 13.1266L1.53256 13.1389L1.53142 13.1411ZM11 10.7708C9.86092 10.7708 8.93751 11.6942 8.93751 12.8333C8.93751 13.9724 9.86092 14.8958 11 14.8958C12.1391 14.8958 13.0625 13.9724 13.0625 12.8333C13.0625 11.6942 12.1391 10.7708 11 10.7708ZM7.56251 12.8333C7.56251 10.9349 9.10153 9.39583 11 9.39583C12.8985 9.39583 14.4375 10.9349 14.4375 12.8333C14.4375 14.7318 12.8985 16.2708 11 16.2708C9.10153 16.2708 7.56251 14.7318 7.56251 12.8333Z" fill="#001F5C"/>
    </g>
    <defs>
    <clipPath id="clip0_1482_10872">
    <rect width="22" height="22" rx="4" fill="white"/>
    </clipPath>
    </defs>
  </svg>
</ng-template>

<ng-template #eyeOffIcon>
  <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_1482_10872)">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M1.53142 13.1411C1.5315 13.141 1.53159 13.1408 0.916672 12.8333C0.301753 12.5259 0.301873 12.5256 0.302006 12.5254L0.302343 12.5247L0.303273 12.5228L0.306148 12.5172L0.315958 12.498C0.324298 12.4817 0.336238 12.4587 0.351751 12.4294C0.382773 12.3708 0.42811 12.2868 0.487548 12.1812C0.606373 11.9699 0.781862 11.6714 1.01232 11.3145C1.47239 10.6022 2.15601 9.65062 3.05053 8.69646C4.82704 6.80151 7.51701 4.8125 11 4.8125C14.483 4.8125 17.173 6.80151 18.9495 8.69646C19.844 9.65062 20.5276 10.6022 20.9877 11.3145C21.2181 11.6714 21.3936 11.9699 21.5125 12.1812C21.5719 12.2868 21.6172 12.3708 21.6483 12.4294C21.6638 12.4587 21.6757 12.4817 21.6841 12.498L21.6939 12.5172L21.6967 12.5228L21.6977 12.5247L21.698 12.5254C21.6981 12.5256 21.6983 12.5259 21.0833 12.8333C20.4684 13.1408 20.4685 13.141 20.4686 13.1411L20.4675 13.1389L20.4612 13.1266C20.4553 13.1151 20.4458 13.0969 20.4329 13.0725C20.4071 13.0237 20.3674 12.9501 20.314 12.8553C20.2073 12.6655 20.0462 12.3911 19.8326 12.0605C19.4047 11.3978 18.7706 10.516 17.9464 9.63688C16.2854 7.86516 13.9337 6.1875 11 6.1875C8.06633 6.1875 5.71463 7.86516 4.05365 9.63688C3.22942 10.516 2.59533 11.3978 2.16738 12.0605C1.95383 12.3911 1.79273 12.6655 1.68596 12.8553C1.63261 12.9501 1.59291 13.0237 1.56708 13.0725C1.55417 13.0969 1.54474 13.1151 1.53881 13.1266L1.53256 13.1389L1.53142 13.1411ZM11 10.7708C9.86092 10.7708 8.93751 11.6942 8.93751 12.8333C8.93751 13.9724 9.86092 14.8958 11 14.8958C12.1391 14.8958 13.0625 13.9724 13.0625 12.8333C13.0625 11.6942 12.1391 10.7708 11 10.7708ZM7.56251 12.8333C7.56251 10.9349 9.10153 9.39583 11 9.39583C12.8985 9.39583 14.4375 10.9349 14.4375 12.8333C14.4375 14.7318 12.8985 16.2708 11 16.2708C9.10153 16.2708 7.56251 14.7318 7.56251 12.8333Z" fill="#001F5C"/>
    </g>
    <defs>
    <clipPath id="clip0_1482_10872">
    <rect width="22" height="22" rx="4" fill="white"/>
    </clipPath>
    </defs>
  </svg>
</ng-template>
