import { trigger, style, transition, animate, group, query, animateChild } from "@angular/animations";

export const fadeInAnimation =
  trigger("fadeIn", [
    transition("void => *", [
      group([
        // query("*", animateChild(), { optional: true }),
        style({ opacity: "0" }),
        animate(200, style({ opacity: "1" }))
      ])
    ]),
  ]);
