<label class="drop" appDragover (drop)="onFileChange($event)">
  <svg class="upload-icon" width="44" height="44" viewBox="0 0 44 44" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g opacity="0.5">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M6.875 26.125V34.8333C6.875 35.4411 7.11644 36.024 7.54621 36.4538C7.97598 36.8836 8.55888 37.125 9.16667 37.125H34.8333C35.4411 37.125 36.024 36.8836 36.4538 36.4538C36.8836 36.024 37.125 35.4411 37.125 34.8333V26.125H39.875V34.8333C39.875 36.1705 39.3438 37.4528 38.3983 38.3983C37.4528 39.3438 36.1705 39.875 34.8333 39.875H9.16667C7.82953 39.875 6.54717 39.3438 5.60167 38.3983C4.65617 37.4528 4.125 36.1705 4.125 34.8333V26.125H6.875Z" fill="#8391A1"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M21.0277 4.52773C21.5647 3.99076 22.4353 3.99076 22.9723 4.52773L33.1112 14.6667L31.1667 16.6112L22 7.44454L12.8333 16.6112L10.8888 14.6667L21.0277 4.52773Z" fill="#8391A1"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M20.625 27.5V5.5H23.375V27.5H20.625Z" fill="#8391A1"/>
    </g>
  </svg>

  <div *ngIf="mode === 'normal'" class="heading">
    Drag & drop here
  </div>

  <div *ngIf="mode === 'dialog'" class="heading">
    Drag & drop documents
  </div>

  <div *ngIf="mode === 'normal'" class="hint">
    or click into area and browse files 
  </div>

  <div *ngIf="mode === 'dialog'" class="hint">
    or click into area
  </div>

  <input type="file" multiple="true" (change)="onFileChange($event)">
</label>

<ng-container *ngIf="mode === 'normal'">
  <ng-container *ngTemplateOutlet="listTemplate">
  </ng-container>
</ng-container>

<app-dialog *ngIf="mode === 'dialog' && isProgressDialogVisible" class="vb-dialog-w656 vb-dialog-dock-bottom vb-dialog-full-width-buttons-mobile">
  <div class="header">
    New Documents

    <div *ngIf="isAnyUploaded" class="hint">
      To add a new document, it is necessary to enter its name.
    </div>

    <svg (click)="onProgressDialogCancelClicked()" class="close" width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fill-rule="evenodd" clip-rule="evenodd" d="M27.1339 3.75107L3.74997 27.135L2.86609 26.2511L26.25 2.86719L27.1339 3.75107Z" fill="#8391A1"/>
      <path fill-rule="evenodd" clip-rule="evenodd" d="M3.74997 2.86719L27.1339 26.2511L26.25 27.135L2.86609 3.75107L3.74997 2.86719Z" fill="#8391A1"/>
    </svg>
  </div>

  <div class="content">
    <ng-container *ngTemplateOutlet="listTemplate">
    </ng-container>
  </div>

  <div class="footer buttons">
    <app-button variant="outline" (click)="onProgressDialogCancelClicked()">{{ isAllGood ? 'Discard' : 'Cancel' }}</app-button>
    <app-button *ngIf="isAllGood" variant="primary" [isLoading]="progressDialogIsSubmitting" (click)="onProgressDialogSubmitClicked()">Add Documents</app-button>
  </div>
</app-dialog>

<ng-template #listTemplate>
  <div class="list">
    <ng-container *ngFor="let file of files" class="file-progress">
      <div class="file-progress">
        <div class="top">
          <div class="filename">
            {{ file.filename }}
          </div>
          <div class="size">
            ({{ formatSize(file.size) }})
          </div>

          <svg (click)="onRemoveClicked(file)" class="delete" width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M14.2956 4.50057L4.50008 14.2961L3.70459 13.5006L13.5001 3.70508L14.2956 4.50057Z" fill="#D03234"/>
            <path fill-rule="evenodd" clip-rule="evenodd" d="M4.50008 3.70508L14.2956 13.5006L13.5001 14.2961L3.70459 4.50057L4.50008 3.70508Z" fill="#D03234"/>
          </svg>
        </div>

        <ng-container *ngIf="!file.attachment && !file.error">
          <app-progress-bar [value]="file.sent" [range]="file.file.size"></app-progress-bar>
        </ng-container>

        <ng-container *ngIf="file.attachment">
          <div class="inputs">
            <app-text-input label="Document Name" [ngModel]="file.attachment.name" (ngModelChange)="onNameChange(file, $event)"></app-text-input>

            <app-dropdown-input *ngIf="mode === 'dialog'" label="Select Policy" [items]="dropdownPolicies" [ngModel]="file.attachment.policy" (ngModelChange)="file.attachment.policy = $event">
              <ng-template let-item #itemTemplate>{{ item?.name }}</ng-template>
              <ng-template let-item #valueTemplate>{{ item?.name }}</ng-template>
            </app-dropdown-input>
          </div>
        </ng-container>

        <ng-container *ngIf="file.error">
          <div (click)="onRetryClicked(file)" class="retry">
            Failed to upload this file, click to try again or remove the file.
            <!-- {{ formatError(file.error) }} -->
          </div>
        </ng-container>
      </div>
    </ng-container>
  </div>
</ng-template>

<!-- <pre> -->
<!-- {{ files | json }} -->
<!-- </pre> -->
