import { Component, ContentChild, ElementRef, HostListener, Input, Optional, TemplateRef, ViewChild } from "@angular/core";
import { ControlValueAccessor, NgControl, ValidationErrors } from "@angular/forms";

@Component({
  selector: "app-textarea-input",
  templateUrl: "./textarea-input.component.html",
  styleUrls: ["./textarea-input.component.scss"],
})
export class TextareaInputComponent implements ControlValueAccessor {

  constructor(
    @Optional() private ngControl: NgControl,
    private el: ElementRef,
  ) {
    if (ngControl) {
      ngControl.valueAccessor = this;
    }
  }
  @Input()
  public type: "text" | "password" | "tel" = "text";

  @Input()
  public placeholder: string;

  @Input()
  public label: string;

  @Input()
  public isInvalid = false;

  @Input()
  public maxlength: number;

  @Input()
  public uppercase: boolean;

  @Input()
  public disabled: boolean;

  @Input()
  public readonly: boolean;

  @Input()
  public rows = 3;

  @ContentChild("suffixTemplate")
  public suffixTemplate?: TemplateRef<null>;

  public value: string;

  private onChane: (value: string) => void;
  private onTouchedFn: () => void;

  private initialHeight: number = 0;
  private height: number = 0;

  @HostListener("click")
  public setFocus(): void {
    this.el.nativeElement.querySelector("textarea").focus();
  }

  public getHasValue(): boolean {
    return !!this.value;
  }

  public getIsInvalid(): boolean {
    return (this.ngControl?.invalid && (this.ngControl?.dirty || this.ngControl.touched) || this.isInvalid);
  }

  public get errors(): ValidationErrors {
    return this.ngControl.errors;
  }

  public onInput(event: any): void {
    const value = event?.target?.value;
    this.value = value;

    this.resize();
    this.onChane && this.onChane(value);
  }

  public onBlur(): void {
    this.onTouchedFn && this.onTouchedFn();
  }

  private resize() {
    const textarea = this.el?.nativeElement?.querySelector("textarea");

    if (!textarea) {
      return;
    }

    const height = Math.max(this.initialHeight, this.height, textarea.scrollHeight);

    if (height !== this.height) {
      this.height = height;
      textarea.style.minHeight = `${this.height}px`;
    }
  }

  public writeValue(obj: string): void {
    this.value = obj;
  }
  public registerOnChange(fn: (value: string) => void): void {
    this.onChane = fn;
  }
  public registerOnTouched(fn: () => void): void {
    this.onTouchedFn = fn;
  }
  public setDisabledState?(isDisabled: boolean): void {
    // throw new Error("Method not implemented.");
  }
}
