import { Directive, Input } from '@angular/core';
import { NgControl, ControlValueAccessor } from '@angular/forms';

@Directive({
  selector: '[appValueTransformer]'
})
export class ValueTransformerDirective implements ControlValueAccessor {
  @Input()
  public appValueTransformer: [(any) => any, (any) => any];

  private valueAccessor: ControlValueAccessor;

  constructor(
    private ngControl: NgControl
  ) {
    // console.log("ngControl: ", this.ngControl.valueAccessor);
    this.valueAccessor = ngControl.valueAccessor;
    ngControl.valueAccessor = this;
  }

  public onControlValueChange(value: any) {
    const transformedOutput = this.appValueTransformer[1](value);

    this.onChangeFn && this.onChangeFn(transformedOutput);
  }

  private onChangeFn: any;

  public writeValue(obj: any): void {
    const transformedInput = this.appValueTransformer[0](obj);

    this.valueAccessor.writeValue(transformedInput);
  }
  public registerOnChange(fn: any): void {
    this.onChangeFn = fn;
    this.valueAccessor.registerOnChange(value => this.onControlValueChange(value));
  }
  public registerOnTouched(fn: any): void {
    this.valueAccessor.registerOnTouched(fn);
  }
  public setDisabledState?(isDisabled: boolean): void {
    this.valueAccessor.setDisabledState && this.valueAccessor.setDisabledState(isDisabled);
  }
}
