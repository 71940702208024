import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from "@angular/router";
import { Store } from "@ngxs/store";
import { catchError, map, Observable, of } from "rxjs";
import { AuthApi } from "../../shared/services/auth.api";
import { UserAccountDto } from "../auth.models";
import { AuthStateActions } from "../state/auth.actions";
import { AuthStateSelectors } from "../state/auth.selectors";

@Injectable({
  providedIn: "root",
})
export class AuthGuard implements CanActivate {
  constructor(
    private store: Store,
    private router: Router,
    private authApi: AuthApi,
  ) { }

  public canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    const account: UserAccountDto = this.store.selectSnapshot(AuthStateSelectors.account);

    if (account) return true;

    return this.authApi.getCurrentUser().pipe(
      catchError(err => {
        return of(this.createUrlTree());
      }),
      map((result: UserAccountDto) => {
        const res: boolean | UrlTree = result?.user
          ? true
          : this.createUrlTree();

        this.store.dispatch(new AuthStateActions.CurrentUser(result));

        return res;
      })
    );
  }

  private createUrlTree() {
    return this.router.createUrlTree(["/auth/login"]);
  }
}
