import { ConnectedPosition, Overlay, OverlayRef } from '@angular/cdk/overlay';
import { TemplatePortal } from '@angular/cdk/portal';
import { Component, ElementRef, EventEmitter, Input, OnInit, Output, TemplateRef, ViewChild, ViewContainerRef } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, Subject, takeUntil } from 'rxjs';

export interface MenuItem {
  id?: string;
  label: string;
  class?: string;
}

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss']
})
export class MenuComponent {
  @Input()
  public items: MenuItem[];

  @Output()
  public itemClick = new EventEmitter<MenuItem>();

  @ViewChild("drawer")
  public drawer: TemplateRef<any>;

  public isOpen = false;

  private destroy$ = new Subject();

  constructor(
    private router: Router,
    private el: ElementRef,
    private overlay: Overlay,
    private viewContainerRef: ViewContainerRef,
  ) { 
    this.router.events.pipe(takeUntil(this.destroy$)).subscribe(() => {
      this.close();
    });

    // setTimeout(() => this.open());
  }

  public onItemClicked(item: any) {
    this.close();
    this.itemClick.next(item);
  }

  public onClicked() {
    this.isOpen ? this.close() : this.open();
  }

  public open() {
    const t = new TemplatePortal(this.drawer, this.viewContainerRef);

    this.overlayRef.attach(t);
    this.isOpen = true;
  }

  private close() {
    this.overlayRef.detach();
    this.isOpen = false;
  }

  private _overlayRef: OverlayRef;
  private get overlayRef() {
    if (this._overlayRef) {
      return this._overlayRef;
    }

    const positions: ConnectedPosition[] = [
      {
        originX: 'start',
        originY: 'bottom',
        overlayX: 'start',
        overlayY: 'top',
        offsetY: 14,
      },
      {
        originX: 'start',
        originY: 'top',
        overlayX: 'start',
        overlayY: 'bottom',
      }
    ];

    const positionStrategy = this.overlay.position()
      .flexibleConnectedTo(this.el)
      .withViewportMargin(20)
      .withPositions(positions);
    const scrollStrategy = this.overlay.scrollStrategies.reposition();
    const hasBackdrop = true;
    const backdropClass = 'cdk-overlay-transparent-backdrop';

    this._overlayRef = this.overlay.create({ positionStrategy, scrollStrategy, hasBackdrop, backdropClass });

    this._overlayRef.backdropClick().pipe(takeUntil(this.destroy$)).subscribe(() => this.close());

    return this._overlayRef;
  }

}
