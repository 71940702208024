import { HttpEventType } from '@angular/common/http';
import { Component, ElementRef, HostBinding, Input, OnDestroy, OnInit, Optional } from '@angular/core';
import { ControlValueAccessor, NgControl } from '@angular/forms';
import { Observable, Subject, takeUntil } from 'rxjs';
import { formatSize } from '../../functions/format-size.function';
import { GcsApi } from '../../services/gcs.api';
import { AttachmentDto } from '../../services/gcs.models';
import { PolicyDto } from '../../services/policies.models';
import * as R from "ramda";

function getFiles(event: any): File[] {
  const fileList: FileList = event.dataTransfer ? event.dataTransfer.files : event.target.files;
  const files = Array.from(fileList);

  // clear value so that the same file can be uploaded immediately again
  event.target.value = '';

  return files;
}

function getFile(event: any): File {
  const files = this.getFiles(event);
  return files.length > 0 ? files[0] : null;
}

interface UploaderFile {
  attachment?: AttachmentDto;
  error?: any;
  file?: File;
  sent?: number;
  filename?: string;
  size?: number;
}

function native2uploader(files: File[]): UploaderFile[] {
  return files.map(file => ({
    file,
    filename: file.name,
    size: file.size,
  }));
}

function attachment2uploader(attachments: AttachmentDto[]): UploaderFile[] {
  return attachments.map(attachment => ({
    attachment,
    filename: attachment.originalFilename,
    size: attachment.sizeBytes,
  }));
}

@Component({
  selector: 'app-document-uploader',
  templateUrl: './document-uploader.component.html',
  styleUrls: ['./document-uploader.component.scss']
})
export class DocumentUploaderComponent implements OnInit, OnDestroy, ControlValueAccessor {
  @Input()
  @HostBinding("class")
  public mode: "normal" | "dialog" = "normal";

  // Dialog Mode
  @Input()
  public policies: any[];
  @Input()
  public submitCallback: (documents: AttachmentDto[]) => Observable<any>
  public dropdownPolicies: PolicyDto[];
  public progressDialogIsSubmitting = false;
  public get isProgressDialogVisible() { return !!this.files?.length }
  public get isAnyUploaded() { return this.files.some(f => !!f.attachment) }
  public get isAllGood() { return this.files?.length && !this.files.some(f => f.error) && this.files.every(f => f.attachment) }

  public files: UploaderFile[] = [];
  public formatSize = formatSize;

  private destroy$ = new Subject();

  constructor(
    private el: ElementRef,
    private gcsApi: GcsApi,
    @Optional() private ngControl: NgControl,
  ) {
    if (this.ngControl) this.ngControl.valueAccessor = this;
  }

  public onNameChange(file: UploaderFile, name: string) {
    file.attachment.name = name;
    this.onChange();
  }

  public openFilePicker() {
    const input = this.el.nativeElement.querySelector("input");
    input && input.click();
  }

  public onProgressDialogCancelClicked() {
    this.files = [];
    this.onChange();
  }

  public onProgressDialogSubmitClicked() {
    this.progressDialogIsSubmitting = true;

    this.submitCallback?.(this.getValue()).pipe(takeUntil(this.destroy$)).subscribe(
      () => {
        this.progressDialogIsSubmitting = false;
        this.onProgressDialogCancelClicked();
      },
      e => {
        this.progressDialogIsSubmitting = false;
      }
    );
  }

  public onFileChange(event) {
    const nativeFiles = getFiles(event);
    const files = native2uploader(nativeFiles);

    console.log("files: ", files);
    this.files = [...this.files, ...files];
    this.upload(files);
    this.progressDialogIsSubmitting = false;
  }

  public upload(files: UploaderFile[]) {
    files.forEach(file => {
      file.error = null;
      file.attachment = null;
      file.sent = 0;

      this.gcsApi.upload({ file: file.file }).pipe(takeUntil(this.destroy$)).subscribe(
        event  => {
          if (event.type === HttpEventType.UploadProgress) {
            file.sent = event.loaded;
          }

          if (event.type === HttpEventType.Response) {
            file.attachment = event.body;
            file.attachment.name = file.attachment.originalFilename;
            
            if (this.mode === "dialog") {
              file.attachment.policy = { name: "General" } as any;
            }
          }

          this.onChange();
        },
        e => {
          file.error = e;
          console.log(e);

          this.onChange();
        }
      );
    });
  }

  public onRetryClicked(file: UploaderFile) {
    this.upload([file]);
  }

  public onRemoveClicked(file: UploaderFile) {
    this.files = this.files.filter(f => f !== file);

    this.onChange();
  }

  public formatError(error: any) {
    return `${error.error.customMessage?.en || error.message || error.toString()}`;
  }

  public ngOnInit() {
    if (this.mode === "dialog") {
      this.dropdownPolicies = [
        { name: "General" } as any,
        ...this.policies
      ];
    }
  }

  public ngOnDestroy() {
    this.destroy$.next(true);
  }

  private getValue() {
    const value: AttachmentDto[] = this.files
      .filter(f => !f.error && f.attachment)
      .map(f => f.attachment);

    return R.clone(value);
  }

  private onChange() {
    this.onChangeFn && this.onChangeFn(this.getValue());
  }

  private onChangeFn: any;

  writeValue(obj: AttachmentDto[]): void {
    if (!obj) {
      this.files = [];
      return;
    }

    this.files = attachment2uploader(obj);
  }
  registerOnChange(fn: any): void {
    this.onChangeFn = fn;
  }
  registerOnTouched(fn: any): void {
    // throw new Error('Method not implemented.');
  }
  setDisabledState?(isDisabled: boolean): void {
    // throw new Error('Method not implemented.');
  }
}
