import { Component } from '@angular/core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'admin';

  constructor() {
    try {
      const meta = document.querySelector("meta[name=build]");
      const build = meta.getAttribute("content");
      const date = new Date(build.split("--")[0])

      console.warn(`This version was build at: ${date}`);
    } catch (e) { }
  }
}
