import { Injectable } from "@angular/core";
import { Observable, tap } from "rxjs";
import { ButtonVariant } from "../components/button/button.component";

export interface Confirmation {
  title: string;
  message: string;

  acceptLabel?: string;
  rejectLabel?: string;

  acceptButtonType?: ButtonVariant;
  rejectButtonType?: ButtonVariant;
}

const defaults: Partial<Confirmation> = {
  acceptButtonType: "primary",
  acceptLabel: "OK",

  rejectButtonType: "outline",
  rejectLabel: "Cancel",
}

export interface ConfirmAndDoParams {
  confirmation: Confirmation;
  action: Observable<any>;
  success?: (any) => void;
  error?: (any) => void;
}

@Injectable({ providedIn: "root" })
export class DialogService {
  public confirmation: Confirmation;
  public isAcceptButtonLoading = false;

  private resolve: (value: any) => void;

  public async confirm(confirmation: Confirmation): Promise<boolean> {
    this.confirmation = { ...defaults, ...confirmation };
    this.isAcceptButtonLoading = false;

    const result = await new Promise<boolean>(resolve => this.resolve = resolve);
    this.confirmation = undefined;

    return result;
  }

  public async confirmAndDo(params: ConfirmAndDoParams) {
    const { confirmation, action, success, error } = params;
    this.confirmation = { ...defaults, ...confirmation };
    this.isAcceptButtonLoading = false;

    const result = await new Promise<boolean>(resolve => this.resolve = resolve);

    if (!result) {
      this.confirmation = undefined;
      return false;
    }

    this.isAcceptButtonLoading = true;

    action.pipe(tap(() => {
      this.confirmation = undefined;
      this.isAcceptButtonLoading = false;
    })).subscribe(
      r => success(r),
      e => error(e)
    );
    // await action.toPromise();


    return true;
  }

  public accept() {
    this.resolve && this.resolve(true);
    this.resolve = undefined;
  }

  public reject() {
    this.resolve && this.resolve(false);
    this.resolve = undefined;
  }
}
