import { Component, Input, OnChanges } from '@angular/core';

@Component({
  selector: 'app-progress-bar',
  templateUrl: './progress-bar.component.html',
  styleUrls: ['./progress-bar.component.scss']
})
export class ProgressBarComponent implements OnChanges {
  @Input()
  public value: number = 0;

  @Input()
  public range: number = 100;

  public width: number = 0;

  public ngOnChanges() {
    this.recalculate();
  }

  private recalculate() {
    this.width = this.value / this.range * 100;
  }
}
