import { Component, ContentChild, ElementRef, HostBinding, HostListener, Input, OnChanges, Optional, SimpleChanges, TemplateRef } from "@angular/core";
import { ControlValueAccessor, NgControl, ValidationErrors } from "@angular/forms";

@Component({
  selector: "app-text-input",
  templateUrl: "./text-input.component.html",
  styleUrls: ["./text-input.component.scss"],
})
export class TextInputComponent implements ControlValueAccessor {

  constructor(
    @Optional() private ngControl: NgControl,
    private el: ElementRef,
  ) {
    if (ngControl) {
      ngControl.valueAccessor = this;
    }
  }
  @Input()
  public type: "text" | "password" | "tel" | "number" = "text";

  @Input()
  public autocomplete: string;

  @Input()
  public placeholder: string;

  @Input()
  public label: string;

  @Input()
  public isInvalid = false;

  @Input()
  public maxlength: number = 255;

  @Input()
  public uppercase: boolean;

  @Input()
  public disabled: boolean;

  @Input()
  public readonly: boolean;

  @Input()
  public pattern: string;

  public _currency = false;
    @Input()
    public set currency(value: boolean) {
      this._currency = value;

      if (value) {
        this.mask = "separator";
      }
    }

  public _integer = false;
  @Input()
  public set integer(value: boolean) {
    this._integer = value;

    if (value) {
      this.mask = "0*";
    }
  }

  @Input()
  public prefix: string;

  @Input()
  public size: string;

  @Input()
  public min: number;

  @Input()
  public max: number;

  @Input()
  @HostBinding("class.hasIcon")
  public icon: string;

  @ContentChild("suffixTemplate")
  public suffixTemplate?: TemplateRef<null>;

  public value: string;
  public showPassword = false;
  public wasPassword = false;
  public mask: string;

  private onChane: (value: string) => void;
  private onTouchedFn: () => void;

  @HostListener("click")
  public setFocus(): void {
    this.el.nativeElement.querySelector("input").focus();
  }

  @HostBinding("class.hasPrefix")
  public get hasPrefix() { return !!this.prefix }

  @HostBinding("class.hasValue")
  public get hasValue() { return this.getHasValue() }

  public getHasValue(): boolean {
    return !!this.value;
  }

  public getIsInvalid(): boolean {
    return (this.ngControl?.invalid && (this.ngControl?.dirty || this.ngControl.touched) || this.isInvalid);
  }

  public get errors(): ValidationErrors {
    return this.ngControl.errors;
  }

  public onModelChange() {
    this.onChane && this.onChane(this.value);
  }

  public onInput(event: any): void {
    const value = event?.target?.value;
    this.value = value;

    this.onChane && this.onChane(value);
  }

  public onBlur(): void {
    this.onTouchedFn && this.onTouchedFn();
  }

  public onEyeClicked(): void {
    this.showPassword = !this.showPassword;
    this.wasPassword = true;

    this.type = this.showPassword
      ? "text"
      : "password";
  }

  public writeValue(obj: string): void {
    this.value = obj;
  }
  public registerOnChange(fn: (value: string) => void): void {
    this.onChane = fn;
  }
  public registerOnTouched(fn: () => void): void {
    this.onTouchedFn = fn;
  }
  public setDisabledState?(isDisabled: boolean): void {
    // throw new Error("Method not implemented.");
  }

  // public ngOnChanges(changes: SimpleChanges) {
    // if ("currency" in changes) {
    //   this.mask = changes["currency"].currentValue ? "separator" : null;
    // }
  // }
}
