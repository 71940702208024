<button type="button" class="text-14-bold" [ngClass]="[variant,size]" [class.hasContent]="hasContent" [class.disabled]="disabled">
  <i *ngIf="leftIcon" class="material-symbols-outlined left">{{ leftIcon }}</i>

  <div style="flex: 1">
    <div class="content" [class.isLoading]="isLoading" #more>
      <ng-content></ng-content>
    </div>
    <app-spinner *ngIf="isLoading" [@fadeIn]></app-spinner>
  </div>

  <mat-icon *ngIf="icon">{{ icon }}</mat-icon>

  <i *ngIf="rightIcon" class="material-symbols-outlined right">{{ rightIcon }}</i>
</button>
