import { animate, group, style, transition, trigger } from '@angular/animations';
import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngxs/store';
import { AuthStateActions } from 'projects/admin/src/modules/auth/state/auth.actions';
import { AuthStateSelectors } from 'projects/admin/src/modules/auth/state/auth.selectors';
import { AuthApi } from 'projects/admin/src/modules/shared/services/auth.api';
import { ToastService } from 'projects/admin/src/modules/shared/services/toast.service';

export interface MenuItem {
  heading: string;
  path?: string;
  class?: string;
  id?: string;
}

const menuItems: MenuItem[] = [
  {
    heading: "Dashboard",
    path: "/dashboard",
  },
  {
    heading: "Users",
    path: "/users",
  },
];

const dropdownItems: MenuItem[] = [
  {
    heading: "Logout",
    class: "red",
    id: "logout",
  },
];

const mobileItems: MenuItem[] = [
  ...menuItems,
  ...dropdownItems,
];

const menuAnimation =
  trigger("menu", [
    transition(":enter", [
      group([
        style({ transform: "translateX(-100%)" }),
        animate("250ms ease", style({ transform: "translateX(0)" }))
      ])
    ]),
    transition(":leave", [
      group([
        animate("250ms ease", style({ transform: "translateX(-100%)" }))
      ])
    ])
  ]);

@Component({
  selector: 'app-top-bar',
  templateUrl: './top-bar.component.html',
  styleUrls: ['./top-bar.component.scss'],
  animations: [menuAnimation],
})
export class TopBarComponent {
  public items: MenuItem[] = menuItems;
  public dropdownItems: MenuItem[] = dropdownItems;
  public mobileItems: MenuItem[] = mobileItems;

  public isItemsMobileOpen = false;

  public userAccount = this.store.selectSnapshot(AuthStateSelectors.account);

  constructor(
    private store: Store,
    private authApi: AuthApi,
    private toast: ToastService,
    private router: Router,
  ) { }

  public onItemClicked(item: MenuItem) {
    item.id === "logout" && this.logout();
  }

  private logout() {
    this.authApi.logout().subscribe(
      () => {
        this.store.dispatch(new AuthStateActions.Logout);
        this.router.navigate(["/auth/login"]);
      },
      e => {
        this.toast.add("Failed to logout", "error", e);
      }
    );
  }
}
