<app-dialog *ngIf="confirmation" class="vb-dialog-full-width-buttons vb-dialog-dock-bottom">
  <div class="header">
    {{ confirmation.title }}

    <svg (click)="onRejectClicked()" class="close" width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fill-rule="evenodd" clip-rule="evenodd" d="M27.1339 3.75107L3.74997 27.135L2.86609 26.2511L26.25 2.86719L27.1339 3.75107Z" fill="#8391A1"/>
      <path fill-rule="evenodd" clip-rule="evenodd" d="M3.74997 2.86719L27.1339 26.2511L26.25 27.135L2.86609 3.75107L3.74997 2.86719Z" fill="#8391A1"/>
    </svg>
  </div>

  <div class="content" [innerHTML]="confirmation.message">
  </div>

  <div class="footer buttons">
    <app-button *ngIf="confirmation.rejectLabel && confirmation.rejectButtonType" [variant]="confirmation.rejectButtonType" (click)="onRejectClicked()">{{ confirmation.rejectLabel }}</app-button>
    <app-button [variant]="confirmation.acceptButtonType" [isLoading]="isAcceptButtonLoading" (click)="onAcceptClicked()">{{ confirmation.acceptLabel }}</app-button>
  </div>
</app-dialog>
