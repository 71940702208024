import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { ServiceWorkerModule } from '@angular/service-worker';
import { NgxsRouterPluginModule } from '@ngxs/router-plugin';
import { NgxsStoragePluginModule, StorageOption } from '@ngxs/storage-plugin';
import { NgxsModule } from '@ngxs/store';
import { environment } from '../environments/environment';
import { AuthState } from '../modules/auth/state/auth.state';
import { SharedModule } from '../modules/shared/shared.module';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { TokenCrossroadsComponent } from './pages/token-crossroads/token-crossroads.component';
import { NgxsLoggerPluginModule } from '@ngxs/logger-plugin';
import { WithCredentialsInterceptor } from '../modules/auth/interceptors/with-credentials.interceptor';
import { LayoutComponent } from './components/layout/layout.component';
import { TopBarComponent } from './components/top-bar/top-bar.component';
import { TokenMobileComponent } from './pages/token-mobile/token-mobile.component';

@NgModule({
  declarations: [
    AppComponent,
    TokenCrossroadsComponent,
    TopBarComponent,
    LayoutComponent,
    TokenMobileComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    AppRoutingModule,
    SharedModule,
    NgxsModule.forRoot([]), 
    NgxsRouterPluginModule.forRoot(),
    // NgxsReduxDevtoolsPluginModule.forRoot(),
    // NgxsLoggerPluginModule.forRoot(),
    NgxsModule.forFeature([AuthState]),
    NgxsStoragePluginModule.forRoot({ key: 'auth', storage: StorageOption.SessionStorage }),

    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: environment.production,
      // Register the ServiceWorker as soon as the application is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: 'registerWhenStable:30000'
    })
  ],
  providers: [
      {
        provide: HTTP_INTERCEPTORS,
        useClass: WithCredentialsInterceptor,
        multi: true,
      },
    ],
  bootstrap: [AppComponent]
})
export class AppModule { }
