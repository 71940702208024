export function roundCurrency(bytes: number) {
  const threshold = 1000;

  if (Math.abs(bytes) < threshold) {
    return `${bytes}`;
  }

  const units = ['k', 'm', 
    // 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'
  ]
  let u = -1;
  const r = 10;

  do {
    bytes /= threshold;
    ++u;
  } while (Math.round(Math.abs(bytes) * r) / r >= threshold && u < units.length - 1);


  return `${bytes.toFixed()}${units[u]}`;
}
