<div class="half">
  <div class="big">
  {{ values.get('active') }}
  </div>

  <div class="small">
    ACTIVE CLIENTS
    <span class="vb-desktop-only">
      ({{ values.get('invited') }} INVITED)
    </span>
  </div>
</div>

<div class="half">
  <div class="big">
  £{{ values.get('premium') }}
  </div>

  <div class="small">
    ACTIVE PREMIUMS
  </div>
</div>
