import { Component, HostBinding, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-icon-button',
  templateUrl: './icon-button.component.html',
  styleUrls: ['./icon-button.component.scss']
})
export class IconButtonComponent {
  @Input()
  @HostBinding("class")
  public variant: "outline" | "red-outline" = "outline";

  constructor() { }


}
