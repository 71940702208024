import { DOCUMENT } from "@angular/common"
import { Inject, Injectable } from "@angular/core"
import { Router } from "@angular/router"
import { RouterDataResolved } from "@ngxs/router-plugin"
import { Action, Selector, State, StateContext, Store } from "@ngxs/store"
import { getLastActivatedRouteSnapshotData } from "../../shared/functions/get-last-activated-route-snapshot-data"
import { UserAccountDto } from "../auth.models"
import { AuthStateActions } from "./auth.actions"

export interface AuthStateModel {
  token?: string;
  verifyCodeToken?: string;
  userAccountDto?: UserAccountDto;

  loginToken?: string;
  loginAccount?: UserAccountDto;
  loginPhoneHint?: string;

  resetPasswordToken?: string;
}

@State<AuthStateModel>({
  name: "auth",
  defaults: { },
})
@Injectable()
export class AuthState {
  constructor(
    private store: Store,
    private router: Router,
    @Inject(DOCUMENT) private document: Document,
  ) { }

  @Action(AuthStateActions.Initialize)
  initialize(ctx: StateContext<AuthStateModel>, action: AuthStateActions.Initialize) {
    return ctx.patchState({ token: action.token, userAccountDto: action.userAccountDto });
  }

  @Action(AuthStateActions.YourDetailsSaved)
  yourDetailsSaved(ctx: StateContext<AuthStateModel>, action: AuthStateActions.YourDetailsSaved) {
    const { user } = ctx.getState().userAccountDto;
    user.phone = action.user.phone;
    user.phonePrefix = action.user.phonePrefix;
    user.password = action.user.password;
    const { userAccountDto } = ctx.getState();
    userAccountDto.user = user;
    return ctx.patchState({ verifyCodeToken: action.token, userAccountDto });
  }

  // @Action(RouterNavigation)
  // routerNavigation(ctx: StateContext<AuthStateModel>, action: RouterNavigation) {
  //   // const { currentStep } = ctx.getState();
  //   // !currentStep && this.store.dispatch(new BookingFormActions.GoStep(defaultBookingFormStep));
  //  }



  @Action(RouterDataResolved)
  public routerDataResolved(ctx: StateContext<AuthStateModel>, action: RouterDataResolved) {
    const data = getLastActivatedRouteSnapshotData(action.routerState.root)
    const documentTitle = `${data.title || ""} - Vanderbilt Insurance`;

    this.document.title = documentTitle;
  }

  @Action(AuthStateActions.Login)
  public login(ctx: StateContext<AuthStateModel>, action: AuthStateActions.Login) {
    ctx.patchState({ loginToken: action.dto.token, loginAccount: action.dto.userAccount, loginPhoneHint: action.dto.phoneHint });
  }

  @Action(AuthStateActions.CurrentUser)
  public currentUser(ctx: StateContext<AuthStateModel>, action: AuthStateActions.CurrentUser) {
    ctx.patchState({ loginAccount: action.dto });
  }

  @Action(AuthStateActions.Logout)
  public logout(ctx: StateContext<AuthStateModel>, action: AuthStateActions.Logout) {
    this.store.reset({});
    // ctx.patchState({ loginAccount: action.dto });
    // ctx.
  }

  @Action(AuthStateActions.SignupVerificationSuccess)
  public signupVerificationSuccess(ctx: StateContext<AuthStateModel>, action: AuthStateActions.SignupVerificationSuccess) {
    ctx.patchState({ 
      loginAccount: action.dto,

      token: undefined,
      verifyCodeToken: undefined,
      userAccountDto: undefined,
    });
  }

  @Action(AuthStateActions.LoginVerificationSuccess)
  public loginVerificationSuccess(ctx: StateContext<AuthStateModel>, action: AuthStateActions.LoginVerificationSuccess) {
    ctx.patchState({ 
      loginAccount: action.dto,

      loginToken: undefined,
      loginPhoneHint: undefined,
    });
  }

  @Action(AuthStateActions.InitializePasswordReset)
  public initializePasswordReset(ctx: StateContext<AuthStateModel>, action: AuthStateActions.InitializePasswordReset) {
    const resetPasswordToken = action.token;
    ctx.patchState({ resetPasswordToken });
  }
}
