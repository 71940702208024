import { Component } from '@angular/core';

@Component({
  selector: 'app-token-mobile',
  templateUrl: './token-mobile.component.html',
  styleUrls: ['./token-mobile.component.scss']
})
export class TokenMobileComponent {

  constructor() { }

  public onAcceptClicked() {
    const url = window.location.toString().replace("https", "vanderbiltinsurance");
    console.log(url);
    window.location = url as any;
  }
}
