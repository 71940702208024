import { Component } from '@angular/core';
import { AuthApi } from 'projects/admin/src/modules/shared/services/auth.api';
import { DashboardApi } from 'projects/admin/src/modules/shared/services/dashboard.api';
import { PoliciesApi } from 'projects/admin/src/modules/shared/services/policies.api';

@Component({
  selector: 'app-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss']
})
export class LayoutComponent {
  constructor(
    private authApi: AuthApi,
    private policiesApi: PoliciesApi,
    private dashboardApi: DashboardApi,
  ) { }
}
