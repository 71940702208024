<input type="date"
       [name]="id"
       placeholder=""
       [class.isInvalid]="getIsInvalid()"
       [class.uppercase]="uppercase"
       [class.hasValue]="getHasValue()"
       [value]="value || null"
       [readOnly]="readonly"
       [attr.disabled]="disabled ? true : null"
       (input)="onInput($event)"
       (blur)="onBlur()"
       #input>

<label *ngIf="label" [for]="id" class="label" [class.hasValue]="getHasValue()">
  {{ label }}
</label>

<div (click)="onIconClicked($event)" class="calendar-icon">
  <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_1771_31804)">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M4 3.75C3.30964 3.75 2.75 4.30964 2.75 5V19C2.75 19.6904 3.30964 20.25 4 20.25H18C18.6904 20.25 19.25 19.6904 19.25 19V5C19.25 4.30964 18.6904 3.75 18 3.75H4ZM1.25 5C1.25 3.48122 2.48122 2.25 4 2.25H18C19.5188 2.25 20.75 3.48122 20.75 5V19C20.75 20.5188 19.5188 21.75 18 21.75H4C2.48122 21.75 1.25 20.5188 1.25 19V5Z" fill="#001F5C"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M15.75 0.25V5.75H14.25V0.25H15.75Z" fill="#001F5C"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M7.75 0.25V5.75H6.25V0.25H7.75Z" fill="#001F5C"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M1.25 9C1.25 8.58579 1.58579 8.25 2 8.25H20C20.4142 8.25 20.75 8.58579 20.75 9C20.75 9.41421 20.4142 9.75 20 9.75H2C1.58579 9.75 1.25 9.41421 1.25 9Z" fill="#001F5C"/>
    </g>
    <defs>
    <clipPath id="clip0_1771_31804">
    <rect width="22" height="22" fill="white"/>
    </clipPath>
    </defs>
  </svg>
</div>

